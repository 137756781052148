import React from "react";
import { Globe } from "react-bootstrap-icons";
import "../css/DocumentsCallOut.css";

function NormativaCallOut(props) {
  return (
    <div className="callout">
      {props.normativa?.map((norma, index) => (
        <p className="linkfitxa mt-2" key={index}>
          <Globe
            style={{ fontSize: "22px", color: "#9e003c" }}
            className="me-2 mb-1"
          />

          <a href={norma.enllas} className="linkfitxa mt-2" target="blank">
            {norma.titol}
          </a>
        </p>
      ))}
    </div>
  );
}

export default NormativaCallOut;
