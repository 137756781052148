import React, { Component } from "react";
import $ from "jquery";
import "../css/Paginacio.css";
class Paginacio extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  comprovarBotons() {
    if (this.props.paginaActual === 1) {
      $("#prev").addClass("disabled");
    } else {
      $("#prev").removeClass("disabled");
    }

    if (this.props.paginaActual === this.props.totalPagines) {
      $("#next").addClass("disabled");
    } else {
      $("#next").removeClass("disabled");
    }
  }

  componentDidMount() {
    this.comprovarBotons();
  }

  render() {
    let pag = [];

    pag.push(
      <li id="prev" className="page-item" key="prev">
        <button
          className="page-link"
          aria-label="Previous"
          onClick={() => this.props.pagNext("prev")}
        >
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>
    );

    for (let i = 1; i <= this.props.totalPagines; i++) {
      if (this.props.paginaActual == i) {
        pag.push(
          <li className="page-item active" key={i}>
            {" "}
            <button
              className="page-link"
              onClick={() => this.props.paginacio(i)}
            >
              {" "}
              {i}
            </button>{" "}
          </li>
        );
      } else {
        pag.push(
          <li className="page-item" key={i}>
            {" "}
            <button
              className="page-link"
              onClick={() => this.props.paginacio(i)}
            >
              {" "}
              {i}
            </button>{" "}
          </li>
        );
      }
    }
    pag.push(
      <li id="next" className="page-item" key="next">
        <button
          className="page-link "
          onClick={() => this.props.pagNext("next")}
          aria-label="Next"
        >
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    );

    this.comprovarBotons();

    return (
      <div>
        <nav aria-label="Page navigation example mr-auto">
          <ul className="pagination pagination-sm">{pag}</ul>
        </nav>
      </div>
    );
  }
}

export default Paginacio;
