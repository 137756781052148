import axios from "axios";

// import SnackbarUtils from "./helper/snackbar-function";

const Axios = axios.create();

const username = "admin";
const password = "admin";
const basicAuth = "Basic " + btoa(username + ":" + password);

Axios.defaults.baseURL = `${process.env.REACT_APP_SERVER_API}api/v1/rolsac`;


// Axios.defaults.baseURL = "http://10.35.3.44:8085/api/v1/rolsac";

Axios.interceptors.request.use(
  function (conf) {
    conf.headers = {
      Authorization: basicAuth,
      "Content-Type": "application/json",
    };

    return conf;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// const errorTypes = {
//   401: () => {
//     SnackbarUtils.error(intl.formatMessage({
//       id: "Comun.error.sin_permisos",
//       defaultMessage: "No posee los permisos suficientes ;("
//     }));
//   },
//   403: () => {
//     clearAll();
//     window.location.href = '/login';
//     SnackbarUtils.error(intl.formatMessage({
//       id: "Comun.error.sesion_expirada",
//       defaultMessage: "Sesión expirada! Vuelva a iniciar sesión."
//     }));
//   },
//   500: () => {
//     SnackbarUtils.error(intl.formatMessage({
//       id: "Comun.error.error_interno",
//       defaultMessage: "Ocurrió un error interno en el servicio X("
//     }));
//   },
//   '_default': () => {
//     SnackbarUtils.error(intl.formatMessage({
//       id: "Comun.error.error_interno",
//       defaultMessage: "Ocurrió un error interno en el servicio X("
//     }));
//   }
// }
// const solveError = (status) => {
//   return errorTypes[status]? errorTypes[status]():errorTypes['_default']();
// }

/** Handle errors */

Axios.interceptors.response.use(
  (response) => {
    // SnackbarUtils.close(key);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Axios;
