import React, { useEffect, useState } from "react";
import { List, Search } from "react-bootstrap-icons";
import "./css/Menu.css";
import Axios from "../Axios";
import $ from "jquery";
import { traduir } from "../Constants";
import { Link, useHistory } from "react-router-dom";

function Menu(props) {
  const [data, setData] = useState([]);
  const [cercar, setCercar] = useState("");
  let history = useHistory();

  useEffect(() => {
    Axios.get("/menus", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {});
  }, []);

  const mostrarDropdown = (num) => {
    $(".dropList").removeClass("show");
    $(`.dropList${num}`).addClass("show");
  };

  const ocultarDropdown = (num) => {
    $(`.dropList${num}`).removeClass("show");
  };

  const recerca = (e) => {
    e.preventDefault();
    history.push(`/cercador?query=${cercar}`);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg nav2">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <List color="white" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {data?.map((menuTitol, categoryIndex) => {
                const linksMenu = menuTitol;
                return (
                  <li
                    className={`nav-item `}
                    onMouseLeave={() => ocultarDropdown(categoryIndex)}
                    key={`1${categoryIndex}`}
                  >
                    <div
                      className="dropdown"
                      id="drop1"
                      onMouseEnter={() => mostrarDropdown(categoryIndex)}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        id={`dropdownMenuLink${categoryIndex}`}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {menuTitol.titol}
                      </a>
                      <ul
                        className={`dropdown-menu dropList dropList${categoryIndex}`}
                        id="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="row">
                          {linksMenu.identificadorFitxes.map(
                            (links, linksIndex) => (
                              <div
                                className="col-xl-4 col-md-6"
                                key={linksIndex}
                              >
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={`/fitxa/${links.codigo}`}
                                  >
                                    {links.titol}
                                  </Link>
                                </li>
                              </div>
                            )
                          )}
                        </div>
                      </ul>
                    </div>
                  </li>
                );
              })}
             
            </ul>
            <form className="d-flex" onSubmit={(e) => recerca(e)}>
              <input
                className="form-control cercar"
                type="search"
                placeholder={traduir("menu.cercador")}
                aria-label="Search"
                onChange={(e) => setCercar(e.target.value)}
              />
              <button
                className="btn btn-outline-primary btnCercador"
                type="submit"
              >
                <Search />
              </button>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Menu;
