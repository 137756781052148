import React, { useEffect, useState } from "react";
import "../css/Fitxa.css";
import "./css/MapaWeb.css";
import Axios from "../../Axios";
import { Link } from "react-router-dom";
import SpinnerLoading from "../SubComponents/SpinnerLoading";

function MapaWeb(props) {
  const [dataMenus, setDataMenus] = useState([]);
  const [dataMenusTramits, setDataMenusTramits] = useState([]);
  const [dataBanners, setDataBanners] = useState([]);
  const [dataPeu, setDataPeu] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    Axios.get("/menus", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setDataMenus(response.data);
        setLoading(false);
      })
      .catch(function (error) {});

    Axios.get("/menuTramit", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setDataMenusTramits(response.data);
        setLoading(false);
      })
      .catch(function (error) {});

    Axios.get("/baners", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setDataBanners(response.data);
        setLoading(false);
      })
      .catch(function (error) {});

    Axios.get("/peu", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setDataPeu(response.data);
        setLoading(false);
      })
      .catch(function (error) {});
  }, []);

  return (
    <div className="col-sm-8 col-md-8 col-lg-9 col-xl-10">
      {loading ? (
        <SpinnerLoading />
      ) : (
        <div className="container">
          <h1 className="titol text-center">{props.data.titol}</h1>

          {dataMenus?.map((menuTitol, categoryIndex) => {
            const linksMenu = menuTitol;
            return (
              <div key={categoryIndex}>
                <p className="mt-3 labelProcediment">
                  <strong>{menuTitol.titol}</strong>
                </p>
                <div className="textProcediment">
                  <ul className="llistaMapa">
                    {linksMenu.identificadorFitxes.map((links, linksIndex) => (
                      <li key={linksIndex}>
                        <Link to={`/fitxa/${links.codigo}`}>{links.titol}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}

          {dataMenusTramits && (
            <>
              <p className="mt-3 labelProcediment">
                <strong>{dataMenusTramits.titol}</strong>
              </p>
              <div className="textProcediment">
                <ul className="llistaMapa">
                  {dataMenusTramits?.tramitsDestacats?.map(
                    (menuTitol, categoryIndex) => {
                      return (
                        <li key={categoryIndex}>
                          <Link to={`/fitxa/${menuTitol.codigo}`}>
                            {menuTitol.titol}
                          </Link>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </>
          )}

          {dataBanners && (
            <>
              <p className="mt-3 labelProcediment">
                <strong>{dataBanners.titol}</strong>
              </p>
              <div className="textProcediment">
                <ul className="llistaMapa">
                  {dataBanners.baners?.map((bannerTitol, categoryIndex) => {
                    if (bannerTitol.url === null) {
                      return (
                        <li key={categoryIndex}>
                          <Link to={`/fitxa/${bannerTitol.codigo}`}>
                            {bannerTitol.titol}
                          </Link>
                        </li>
                      );
                    } else {
                      return (
                        <li key={categoryIndex}>
                          <a href={bannerTitol.url} target="blank">
                            {bannerTitol.titol}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </>
          )}

          {dataPeu && (
            <>
              <p className="mt-3 labelProcediment">
                <strong>{dataPeu.titol}</strong>
              </p>
              <div className="textProcediment">
                <ul className="llistaMapa">
                  {dataPeu.fitxesPeu?.map((peu, categoryIndex) => {
                    if (peu.url === null) {
                      return (
                        <li key={categoryIndex}>
                          <Link to={`/fitxa/${peu.codigo}`}>{peu.titol}</Link>
                        </li>
                      );
                    } else {
                      return (
                        <li key={categoryIndex}>
                          <a href={peu.url} target="blank">
                            {peu.titol}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default MapaWeb;
