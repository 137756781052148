import React, { useEffect, useState } from "react";
import "./css/Tramits.css";
import MapaWeb from "./PaginesEspecials/MapaWeb";
import DataHora from "./PaginesEspecials/DataHota";
import Fitxa from "./Fitxa2";
import TaulerAnuncis from "./PaginesEspecials/TaulerAnuncis";
import CatalegProcediments from "./PaginesEspecials/CatalegProcediments";
import Geolocatizacio from "./PaginesEspecials/Geolocalitzacio";

import Axios from "../Axios";
import Organigrama from "./PaginesEspecials/Organigrama";
function DistribuidorPagines(props) {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    Axios.get(`/propietats`, {})
      .then(function (response) {
        setProperties(response.data);
      })
      .catch(function (error) {});
  }, []);

  let seccioPag = "fitxa";
  props.seccions.map((seccio) => {
    if (seccio.codiEstandar === properties.mapaWeb) {
      seccioPag = seccio.codiEstandar;
    } else if (seccio.codiEstandar === properties.datahora) {
      seccioPag = seccio.codiEstandar;
    } else if (seccio.codiEstandar === properties.taulerAnuncis) {
      seccioPag = seccio.codiEstandar;
    } else if (seccio.codiEstandar === properties.procedimiento) {
      seccioPag = seccio.codiEstandar;
    } else if (seccio.codiEstandar === properties.geolocalitzacio) {
      seccioPag = seccio.codiEstandar;
    } else if (seccio.codiEstandar === properties.organigrama) {
      seccioPag = seccio.codiEstandar;
    }
  });

  switch (seccioPag) {
    case "fitxa":
      return (
        <Fitxa
          codi={props.codi !== undefined ? props.codi : ""}
          data={props.data}
          imatge={props.imatge}
          errorServei={props.errorServei}
          mostrarMapaWeb={props.mostrarMapaWeb}
          mostrarDataHora={props.mostrarDataHora}
          loading={props.loading}
        />
      );
    case properties.procedimiento:
      return (
        <CatalegProcediments titol={props.data.titol}></CatalegProcediments>
      );
    case properties.taulerAnuncis:
      return (
        <TaulerAnuncis
          data={props.data}
          errorServei={props.errorServei}
          loading={props.loading}
        />
      );
    case properties.mapaWeb:
      return (
        <MapaWeb
          data={props.data}
          errorServei={props.errorServei}
          loading={props.loading}
        />
      );
    case properties.datahora:
      return (
        <DataHora
          data={props.data}
          errorServei={props.errorServei}
          loading={props.loading}
        />
      );
    case properties.geolocalitzacio:
      return (
        <Geolocatizacio
          data={props.data}
          errorServei={props.errorServei}
          loading={props.loading}
        />
      );
    case properties.organigrama:
      return(
        <Organigrama 
          data={props.data}
          errorServei={props.errorServei}
          loading={props.loading}
        />
      );
    default:
      return (
        <Fitxa
          codi={props.codi !== undefined ? props.codi : ""}
          data={props.data}
          imatge={props.imatge}
          errorServei={props.errorServei}
          mostrarMapaWeb={props.mostrarMapaWeb}
          mostrarDataHora={props.mostrarDataHora}
          loading={props.loading}
        />
      );
  }

  //   if (props.codi === process.env.REACT_APP_MAPA_WEB_CODI) {
  //     return (
  //       <MapaWeb
  //         data={props.data}
  //         errorServei={props.errorServei}
  //         loading={props.loading}
  //       />
  //     );
  //   } else if (props.codi === process.env.REACT_APP_DATA_HORA_CODI) {
  //     return (
  //       <DataHora
  //         data={props.data}
  //         errorServei={props.errorServei}
  //         loading={props.loading}
  //       />
  //     );
  //   } else if (props.codi === process.env.REACT_APP_TAULER_ANUNCIS_CODI) {
  //     return (
  //       <TaulerAnuncis
  //         data={props.data}
  //         errorServei={props.errorServei}
  //         loading={props.loading}
  //       />
  //     );
  //   } else if (props.codi === process.env.REACT_APP_CATALEG_PROCEDIMENTS_CODI) {
  //     return <CatalegProcediments titol={props.data.titol}></CatalegProcediments>;
  //   } else {
  //     return (
  //       <Fitxa
  //         codi={props.codi !== undefined ? props.codi : ""}
  //         data={props.data}
  //         imatge={props.imatge}
  //         errorServei={props.errorServei}
  //         mostrarMapaWeb={props.mostrarMapaWeb}
  //         mostrarDataHora={props.mostrarDataHora}
  //         loading={props.loading}
  //       />
  //     );
  //   }
}

export default DistribuidorPagines;
