import ca from "./Traducciones/ca.json";
import es from "./Traducciones/es.json";

export const primary = "#9e003c";

export function traduir(string) {
  const lang = localStorage.getItem("lang");
  let idiomes = {
    ca,
    es,
  };

  return idiomes[lang][string];
}

export function agafarIdioma() {
  let idioma = "";
  if (localStorage.getItem("lang") === null) {
    const lang = window.navigator.language;
    if (lang.includes("es")) {
      idioma = "es";
    } else if (lang.includes("ca")) {
      idioma = "ca";
    } else {
      idioma = "es";
    }

    localStorage.setItem("lang", idioma);
  } else {
    idioma = localStorage.getItem("lang");
  }

  return idioma;
}


