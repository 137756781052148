import React from "react";
import "../css/Tramits.css";
import { Link } from "react-router-dom";

function Banners(props) {
  return (
    <div className="col pe-2 ps-2 pb-2" key={props.baner.codigo}>
      {" "}
      {props.baner.url === null ? (
        <Link
          className="textGris"
          to={`/fitxa/${props.baner.codigo}`}
          key={props.baner.codigo}
        >
          <img
            src={`data:;base64,${props.baner.imatge}`}
            width="100%"
            height="70px"
            alt={props.baner.titol}
            className="imgBanner"
          />
        </Link>
      ) : (
        <a href={props.baner.url}>
          <img
            src={`data:;base64,${props.baner.imatge}`}
            width="100%"
            height="70px"
            alt={props.baner.titol}
            className="imgBanner"
          />
        </a>
      )}
    </div>
  );
}

export default Banners;
