import React from "react";
import "./css/Procediment.css";
import Axios from "../Axios";
import Traduccio from "./Traduccio";
import SpinnerLoading from "./SubComponents/SpinnerLoading";
import TramitProcediment from "./SubComponents/TramitProcediment";
import DocumentsCallOut from "./SubComponents/DocumentsCallOut";
import NormativaCallOut from "./SubComponents/NormativaCallOut";
import logoPdf from "../Img/icon_pdf.png";
import logoWord from "../Img/icon.doc.png";
import logoExcel from "../Img/icon_excel.png";

function Procediments(props) {
  function documents(codi, nom) {
    Axios.get(`/arxiusStreaming`, {
      params: {
        id: codi,
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nom);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (error) {});
  }

  const tipusArxiu = (mime) => {
    if (mime?.includes("pdf")) {
      return logoPdf;
    } else if (mime?.includes("excel")) {
      return logoExcel;
    } else {
      return logoWord;
    }
  };

  return (
    <div className="col-sm-8 col-md-8 col-lg-9 col-xl-10">
      {props.loading ? (
        <SpinnerLoading />
      ) : props.errorProcediment ? (
        <div className="container errorContainer text-center">
          {" "}
          <p className="mt-4">
            <Traduccio string="procediment.error" />
          </p>
        </div>
      ) : (
        <div className="container-fluid containerFitxa">
          <h1 className="titol text-center mt-3">
            {props.dataProcediment.nom}
          </h1>

          <div className="row">
            <div className="labelProcediment text">
              <Traduccio string="procediment.codiSia" />
              <span className="textProcediment ms-2">
                {props.dataProcediment.codiSIA ?? "---"}
              </span>
            </div>
          </div>

          <p className="labelProcediment text mt-3">
            <Traduccio string="procediment.objecte" />
          </p>
          <p className="textProcediment">{props.dataProcediment.objecte}</p>

          <div className="row mt-4">
            <div className="labelProcediment text">
              <Traduccio string="procediment.destinataris" />
              <span className="textProcediment ms-2">
                {props.dataProcediment.destinataris}
              </span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="labelProcediment text">
              <Traduccio string="procediment.iniciacio" />
              <span className="textProcediment ms-2">
                {props.dataProcediment.formaIniciacio}
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="labelProcediment text">
              <Traduccio string="procediment.terminiNotificacio" />
              <span className="textProcediment ms-2">
                {props.dataProcediment.terminiMaximNotificacio}
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="labelProcediment text">
              <Traduccio string="procediment.silenci" />
              <span className="textProcediment ms-2">
                {props.dataProcediment.silenciAdministratiu}
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="labelProcediment text">
              <Traduccio string="procediment.fi" />
              <span className="textProcediment ms-2">
                {props.dataProcediment.fiViaAdministrativa ? (
                  <Traduccio string="comu.si" />
                ) : (
                  <Traduccio string="comu.no" />
                )}
              </span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="labelProcediment text">
              <Traduccio string="procediment.taxa" />
              <span className="textProcediment ms-2">
                {props.dataProcediment.taxa ? (
                  <Traduccio string="comu.si" />
                ) : (
                  <Traduccio string="comu.no" />
                )}
              </span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="labelProcediment text">
              <Traduccio string="procediment.organ" />
              <span className="textProcediment ms-2">
                {props.dataProcediment.organCompetentPerResoldre}
              </span>
            </div>
          </div>
          {props.dataProcediment.observacions && (
            <>
              <p className="labelProcediment text mt-4">
                <Traduccio string="procediment.observacions" />
              </p>
              <p className="textProcediment">
                {props.dataProcediment.observacions}
              </p>
            </>
          )}

          {props.dataDocuments && (
            <>
              <p className="labelProcediment text mt-4">
                <Traduccio string="procediment.documents" />
              </p>
              <DocumentsCallOut infoDocuments={props.dataDocuments} />
            </>
          )}
          {props.dataNormativa && (
            <>
              <p className="labelProcediment text mt-4">
                <Traduccio string="procediment.normativa" />
              </p>
              <NormativaCallOut normativa={props.dataNormativa} />
            </>
          )}
          {props.dataTramit && (
            <>
              <p className="labelProcediment text mt-4">
                <Traduccio string="procediment.tramits" />
              </p>
              <div className="callout">
                <TramitProcediment tramits={props.dataTramit} />
              </div>
            </>
          )}
          <p className="labelProcediment text mt-4">
            <Traduccio string="procediment.informacioProteccioDades" />
          </p>
          <div className="textProcediment">
            <ul className="llistaMapa">
              <li>
                <p className="card-title titolTramit mt-3">
                  <Traduccio string="proteccioDades.responsable" />
                </p>
                <p className="card-text">
                  {props.dataProcediment.proteccioDades
                    ?.responsableTractament ?? "---"}
                </p>
              </li>
              <li>
                <p className="card-title titolTramit mt-3">
                  <Traduccio string="proteccioDades.finalitat" />
                </p>
                <p className="card-text">
                  {props.dataProcediment.proteccioDades.finalitat}
                </p>
              </li>
              <li>
                <p className="card-title titolTramit mt-3">
                  <Traduccio string="proteccioDades.legitimacio" />
                </p>
                <p className="card-text">
                  {props.dataProcediment.proteccioDades.ligitimacio}
                </p>
              </li>
              <li>
                <p className="card-title titolTramit mt-3">
                  <Traduccio string="proteccioDades.destinatari" />
                </p>
                <p className="card-text">
                  {props.dataProcediment.proteccioDades.destinatari}
                </p>
              </li>
              <li>
                <p className="card-title titolTramit mt-3">
                  <Traduccio string="proteccioDades.drets" />
                </p>
                <p className="card-text">
                  {props.dataProcediment.proteccioDades.drets}
                </p>
              </li>
              <li>
                <p className="card-title titolTramit mt-3">
                  <Traduccio string="proteccioDades.documentAdicional" />
                </p>
                <div className="linkfitxa mt-2">
                  <img
                    src={tipusArxiu(
                      props.dataProcediment.proteccioDades.arxiuInfoAdicional
                        .mime
                    )}
                    className="me-2 mb-2"
                    width="24px"
                    alt="tipusArxiu"
                  />
                  <button
                    className="linkDocumentPD pt-2"
                    onClick={() =>
                      documents(
                        props.dataProcediment.proteccioDades.arxiuInfoAdicional
                          .codigo,
                        props.dataProcediment.proteccioDades.arxiuInfoAdicional
                          .nombre
                      )
                    }
                  >
                    {
                      props.dataProcediment.proteccioDades.arxiuInfoAdicional
                        .nombre
                    }
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Procediments;
