import React, { useEffect, useState } from "react";
import "./css/CatalegProcediments.css";
import Axios from "../../Axios";
import Traduccio from "../Traduccio";
import SpinnerLoading from "../SubComponents/SpinnerLoading";
import { ArrowLeft, ArrowRight, Search, X } from "react-bootstrap-icons";
import { traduir } from "../../Constants";
import $ from "jquery";
import CardResultats from "../SubComponents/CardResultats";
import Paginacio from "../SubComponents/Paginacio";

function CatalegProcediments(props) {
  const [dataMateries, setDataMateries] = useState([]);
  const [dataOrgans, setDataOrgans] = useState([]);
  const [dataPublic, setDataPublic] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cercar, setCercar] = useState();
  const [telematic, setTelematic] = useState(false);
  const [materiaFiltres, setMateriaFilter] = useState([""]);
  const [organsFiltres, setOrgansFiltres] = useState([""]);
  const [publicFiltre, setPublicFiltre] = useState([""]);
  const [mostrarResultats, setMostrarResultats] = useState(false);
  const [resultats, setResultats] = useState([]);
  const [totalPagines, setTotalPagines] = useState();
  const [paginaActual, setPaginaActual] = useState(1);
  const [resultatsPerPagina] = useState(10);

  const recerca = (event) => {
    event.preventDefault();
    setLoading(true);
    Axios.post(
      `/cercarProcediments?lang=${localStorage.getItem(
        "lang"
      )}&page=${paginaActual}&pageSize=${resultatsPerPagina}`,
      {
        paraulesClau: cercar,
        codisMateria: materiaFiltres ? materiaFiltres : [""],
        tramitacioTelematica: telematic,
        codisPublicObjectiu: publicFiltre ? publicFiltre : [""],
        codisOrganCompetentPerResoldre : organsFiltres ? organsFiltres : [""]
      }
    )
      .then(function (response) {
        setResultats(response.data);
        setLoading(false);
        setMostrarResultats(true);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const afegirMateria = (codi) => {
    let materies = materiaFiltres;
    if (materies.length >= 0) {
      if (materies[0] === "") {
        materies = [];
      }
    }

    materies.push(codi);
    setMateriaFilter(materies);
  };

  const llevarMateria = (codi) => {
    let materies = materiaFiltres;
    if (materies.length === 1) {
      materies = [""];
    } else {
      materies.map((materia, index) => {
        if (materia === codi) {
          materies.splice(index, 1);
        }
      });
    }
    setMateriaFilter(materies);
  };

  const afegirOrgan = (codi) => {
    let organs = organsFiltres;
    if (organs.length >= 0) {
      if (organs[0] === "") {
        organs = [];
      }
    }

    organs.push(codi);
    setOrgansFiltres(organs);
  };

  const llevarOrgan = (codi) => {
    let organs = organsFiltres;
    if (organs.length === 1) {
      organs = [""];
    } else {
      organs.map((organ, index) => {
        if (organ === codi) {
          organs.splice(index, 1);
        }
      });
    }

    setOrgansFiltres(organs);
  };

  const borrarFiltres = () => {
    $("input").filter(":checkbox").prop("checked", false);
    $("#cercador").prop("value", "");
    $("#selector").prop("value", "");
    setOrgansFiltres([""]);
    setMateriaFilter([""]);
    setTelematic(false);
    setCercar("");
    setPublicFiltre([""]);
  };

  function provarCheck(codi) {
    if (materiaFiltres.indexOf(codi) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  function provarCheckOrgans(codi) {
    if (organsFiltres.indexOf(codi) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  const tornarACercar = () => {
    setMostrarResultats(false);
  };

  useEffect(() => {
    setMostrarResultats(false);
    setLoading(true);

    Axios.post(`/materies?lang=${localStorage.getItem("lang")}`, {})
      .then(function (response) {
        setDataMateries(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });

    Axios.post(
      `/unitatsAdministratives?lang=${localStorage.getItem("lang")}`,
      {}
    )
      .then(function (response) {
        setDataOrgans(response.data.resultado);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });

    Axios.post(`/publicsObjectiu?lang=${localStorage.getItem("lang")}`, {})
      .then(function (response) {
        setDataPublic(response.data.resultado);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, []);

  const paginacioNext = async (pag) => {
    setLoading(true);

    Axios.post(
      `/cercarProcediments?lang=${localStorage.getItem("lang")}&page=${
        pag === "next" ? paginaActual + 1 : paginaActual - 1
      }&pageSize=${resultatsPerPagina}`,
      {
        paraulesClau: cercar,
        codisMateria: materiaFiltres ? materiaFiltres : [""],
        tramitacioTelematica: telematic,
        codisPublicObjectiu: publicFiltre ? publicFiltre : [""],
        // codisOrganCompetentPerResoldre : organsFiltres ? organsFiltres : [""]
      }
    )
      .then(function (response) {
        setResultats(response.data);
        setLoading(false);
        setMostrarResultats(true);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });

    setPaginaActual(pag === "next" ? paginaActual + 1 : paginaActual - 1);
  };

  const paginacio = async (num_pag) => {
    setLoading(true);

    Axios.post(
      `/cercarProcediments?lang=${localStorage.getItem(
        "lang"
      )}&page=${num_pag}&pageSize=${resultatsPerPagina}`,
      {
        paraulesClau: cercar,
        codisMateria: materiaFiltres ? materiaFiltres : [""],
        tramitacioTelematica: telematic,
        codisPublicObjectiu: publicFiltre ? publicFiltre : [""],
        // codisOrganCompetentPerResoldre : organsFiltres ? organsFiltres : [""]
      }
    )
      .then(function (response) {
        setResultats(response.data);
        setLoading(false);
        setMostrarResultats(true);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });

    setPaginaActual(num_pag);

    setLoading(false);
  };

  return (
    <div className="col-sm-8 col-md-8 col-lg-9 col-xl-10">
      {loading ? (
        <SpinnerLoading />
      ) : !mostrarResultats ? (
        <div className="container-fluid containerFitxa">
          <h1 className="titol text-center">{props.titol}</h1>

          <div className="d-flex flex-row-reverse">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => recerca(e)}
            >
              <Traduccio string="cataleg.cercar" />
              <ArrowRight className="ms-2" fontSize={20} />
            </button>
            <button
              type="button"
              className="btn btn-outline-primary me-2"
              onClick={() => {
                borrarFiltres();
              }}
            >
              <Traduccio string="cataleg.borrarFiltres" />
              <X className="ms-2" fontSize={20} />
            </button>
          </div>
          <h4 className="sub-titol-cercador">
            <Traduccio string="cataleg.cercador" />
          </h4>
          <form
            className="d-flex col-10 mt-3 mb-4"
            onSubmit={(e) => recerca(e)}
          >
            <input
              className="form-control cercarProcediment"
              type="search"
              placeholder={traduir("menu.cercadorProcediments")}
              aria-label="Search"
              id="cercador"
              defaultValue={cercar}
              onChange={(e) => e.preventDefault() + setCercar(e.target.value)}
            />
            <button
              className="btn btn-outline-primary btnRecerca"
              type="submit"
              onClick={(e) => recerca(e)}
            >
              <Search />
            </button>
          </form>
          <h4 className="sub-titol-cercador mb-3">
            <Traduccio string="cataleg.materies" />
          </h4>
          <div className="row ms-2 mb-3 col-11 " id="materies">
            {dataMateries?.resultado?.map((materia, index) => {
              return (
                <div
                  className="form-check col-3 mb-2"
                  id={materia.codigo}
                  key={index}
                >
                  <input
                    className={`form-check-input`}
                    type="checkbox"
                    id={materia.codigo}
                    defaultChecked={provarCheck(materia.codigo)}
                    onChange={(e) =>
                      e.target.checked
                        ? afegirMateria(materia.codigo)
                        : llevarMateria(materia.codigo)
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    {materia.nombre}
                  </label>
                </div>
              );
            })}
          </div>

          <h4 className="sub-titol-cercador mt-3 mb-3">
            <Traduccio string="cataleg.public" />
          </h4>
          <div className="row ms-1 mb-3 col-3">
            <select
              className="form-select"
              aria-label="PublicObjectiu"
              id="selector"
              defaultValue={publicFiltre[0]}
              onChange={(e) => {
                setPublicFiltre([""]);
                setPublicFiltre([e.target.value]);
              }}
            >
              <option value="">{traduir("cataleg.select.tots")}</option>
              {dataPublic?.map((publicObjectiu, index) => {
                return (
                  <option value={publicObjectiu.codigo} key={index}>
                    {" "}
                    {publicObjectiu.titulo}
                  </option>

                  // <div className="form-check col-3 mb-2" key={index}>
                  //   <input
                  //     className="form-check-input"
                  //     type="checkbox"
                  //     id={publicObjectiu.titulo}
                  //     value={publicObjectiu.codigo}
                  //   />
                  //   <label className="form-check-label" htmlFor="inlineCheckbox1">
                  //     {publicObjectiu.titulo}
                  //   </label>
                  // </div>
                );
              })}
            </select>
          </div>
          <h4 className="sub-titol-cercador mt-3 mb-3">
            <Traduccio string="cataleg.tramit" />
          </h4>
          <div className="row ms-2 mb-3 col-11">
            <div className="form-check col-3 mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                id={"telematic"}
                value={"telematic"}
                checked={telematic}
                onChange={(e) => setTelematic(e.target.checked)}
              />

              <label className="form-check-label " htmlFor="inlineCheckbox1">
                <Traduccio string="cataleg.tramit" />
              </label>
            </div>
          </div>
          <h4 className="sub-titol-cercador mt-3 mb-3">
            <Traduccio string="cataleg.organ" />
          </h4>
          <div className="row ms-2 mb-3 col-11">
            {dataOrgans?.map((organ, index) => {
              return (
                <div className="form-check col-3 mb-2" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={organ.nombre}
                    defaultChecked={provarCheckOrgans(organ.codigo)}
                    onChange={(e) =>
                      e.target.checked
                        ? afegirOrgan(organ.codigo)
                        : llevarOrgan(organ.codigo)
                    }
                  />

                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    {organ.nombre}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="container-fluid containerFitxa">
          <h1 className="titol text-center">
            <Traduccio string="cataleg.resultats" />
          </h1>

          <div className="d-flex flex-row-reverse mb-4">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => tornarACercar()}
            >
              <ArrowLeft className="me-2" fontSize={20} />
              <Traduccio string="cataleg.tornar" />
            </button>
          </div>
          {resultats.numeroElementos === 0 && (
            <div className="noResultats">
              <Traduccio string="cataleg.noCoincidenica" />
            </div>
          )}
          {resultats?.identificadorsProcediment.map((result, index) => {
            return <CardResultats key={index} data={result} />;
          })}

          {resultats.numeroElementos > resultatsPerPagina && (
            <div className="row mt-5 m-0 justify-content-center">
              <div className="col-auto">
                <Paginacio
                  totalPagines={totalPagines}
                  paginaActual={paginaActual}
                  pagNext={paginacioNext}
                  paginacio={paginacio}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CatalegProcediments;
