import React, { useEffect, useState } from "react";
import Axios from "../Axios";
import Header from "../Components/Header";
import Menu from "../Components/Menu";
import Tramits from "../Components/Tramits";
import Fitxa from "../Components/Fitxa2";
import Footer from "../Components/Footer2";
import BreadCrumb from "../Components/BreadCrumb";
import { useLocation, useParams } from "react-router";
import DistribuidorPagines from "../Components/DistribudorPagines";
import SpinnerLoading from "../Components/SubComponents/SpinnerLoading";
import Traduccio from "../Components/Traduccio";
import Procediments from "../Components/Procediments";
import { traduir } from "../Constants";
import ResultatsCercador from "../Components/PaginesEspecials/ResultatsCercador";

function Index(props) {
  let params = useParams();
  let location = useLocation();
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [data, setData] = useState([]);
  const [imatge, setImatge] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorServei, setErrorServei] = useState(false);
  const [mostrarMapaWeb] = useState(params.codi);
  const [loadingTramits, setLoadingTramits] = useState(false);
  const [dataTramit, setDataTramit] = useState([]);
  const [mostrarDataHora] = useState(false);
  const [mostrarProcediment, setMostrarProcediment] = useState(false);
  const [mostrarCerca, setMostrarCerca] = useState(false);

  const [dataProcediment, setDataProcediment] = useState([]);
  const [dataDocuments, setDataDocuments] = useState([]);
  const [dataNormativa, setDataNormativa] = useState([]);
  const [dataTramits, setDatatramits] = useState([]);
  const [errorProcediment, setErrorProcediment] = useState(false);
  const [titolBreadCurmb, setTitolBreadCrumb] = useState();
  const [resultatsCerca, setResultatsCerca] = useState([]);
  const [identificadorsSeccions, setIdentificadorsSeccions] = useState([]);
  const [mostrarAnunci, setMostrarAnunci] = useState([]);

  useEffect(() => {
    setLoadingTramits(true);
    Axios.get("/menuTramit", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setDataTramit(response.data.tramitsDestacats);
        setLoadingTramits(false);
      })
      .catch(function (error) {
        setLoadingTramits(false);
        setErrorServei(true);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const ruta = location.pathname.split("/");
    if (ruta[1] === "") {
      setMostrarProcediment(false);
      setMostrarCerca(false);
      setMostrarAnunci(false);

      Axios.get(`/iniciSeu`, {
        params: {
          lang: localStorage.getItem("lang"),
        },
      })
        .then(function (response) {
          setData(response.data.inicis[0]);
          setImatge("data:;base64," + response.data.inicis[0].imatge);
          setTitolBreadCrumb(response.data.inicis[0].titol);
          setErrorServei(false);
          setLoading(false);
          setIdentificadorsSeccions(
            response.data.inicis[0].identificadorSeccions
          );
        })
        .catch(function (error) {
          setErrorServei(true);
          setLoading(false);
        });
    } else if (ruta[1] === "fitxa") {
      setMostrarProcediment(false);
      setMostrarCerca(false);
      setMostrarAnunci(false);

      if (params.codi === undefined) {
        // } else if (params.codi === process.env.REACT_APP_MAPA_WEB_CODI) {
        //   setMostrarMapaWeb(true);
        //   setLoading(false);
        // } else if (params.codi === process.env.REACT_APP_DATA_HORA_CODI) {
        //   setMostrarDataHora(true);
        //   setLoading(false);
      } else {
        Axios.get(`/fitxaCos/${params.codi}`, {
          params: {
            lang: localStorage.getItem("lang"),
          },
        })
          .then(function (response) {
            setData(response.data);
            setImatge("data:;base64," + response.data.imatge);
            setTitolBreadCrumb(response.data.titol);
            setErrorServei(false);
            setLoading(false);
            setIdentificadorsSeccions(response.data.identificadorSeccions);
          })
          .catch(function (error) {
            setErrorServei(true);
            setLoading(false);
          });
      }
    } else if (ruta[1] === "procediment") {
      Axios.get(
        `/procedimentCustom/${params.codi}?lang=${localStorage.getItem(
          "lang"
        )}`,
        {}
      )
        .then(function (response) {
          setDataProcediment(response.data);
          setDataDocuments(response.data.identificadorsDocument);
          setDataNormativa(response.data.identificadorsNormativa);
          setDatatramits(response.data.tramits);
          setTitolBreadCrumb(response.data.nom);
          setLoading(false);
          setErrorProcediment(false);
        })
        .catch(function (error) {
          setLoading(false);
          setErrorProcediment(true);
        });
      setMostrarProcediment(true);
      setMostrarAnunci(false);
      setMostrarCerca(false);
    } else if (ruta[1] === "cercador") {
      setMostrarCerca(true);
      setMostrarAnunci(false);
      setMostrarProcediment(false);
      setTitolBreadCrumb(traduir("cataleg.resultats"));
      recerca();
    } else if (ruta[1] === "anunci") {
      Axios.get(`/fitxaCos/${params.codi}`, {
        params: {
          lang: localStorage.getItem("lang"),
        },
      })
        .then(function (response) {
          setData(response.data);
          setImatge("data:;base64," + response.data.imatge);
          setTitolBreadCrumb(response.data.titol);
          setErrorServei(false);
          setLoading(false);
        })
        .catch(function (error) {
          setErrorServei(true);
          setLoading(false);
        });

      setMostrarAnunci(true);
      setMostrarCerca(false);
      setMostrarProcediment(false);
    }
  }, [location.pathname, params.codi, location.search]);

  const recerca = () => {
    setLoading(true);
    Axios.post(`/cercar?lang=${localStorage.getItem("lang")}`, {
      paraulesClau: query.get("query"),
    })
      .then(function (response) {
        setResultatsCerca(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const agafarTitol = () => {
    if (mostrarProcediment) {
      return {
        titol: <Traduccio string="catalegProcediments.titol" />,
        subtitol: titolBreadCurmb,
      };
    } else if (mostrarAnunci) {
      return {
        titol: <Traduccio string="tauler.anuncis" />,
        subtitol: titolBreadCurmb,
      };
    } else {
      return { titol: titolBreadCurmb, subtitol: "" };
    }
  };

  return (
    <>
      <div className="container-fluid">
        <Header canviarLlenguatge={props.canviarLlenguatge} />
      </div>
      <Menu cerca={recerca} />
      {loadingTramits ? (
        <SpinnerLoading />
      ) : (
        <>
          <BreadCrumb titolFitxa={agafarTitol()} />
          <div className="container-fluid">
            <div className="row">
              <Tramits loadingTramits={loadingTramits} data={dataTramit} />

              {errorServei ? (
                <div className="col-sm-8 col-md-8 col-lg-9 col-xl-10 errorContainer text-center">
                  {" "}
                  <p className="mt-4">
                    <Traduccio string="fitxa.errorServei" />
                  </p>
                </div>
              ) : !mostrarProcediment && !mostrarCerca && !mostrarAnunci ? (
                <DistribuidorPagines
                  codi={params.codi !== undefined ? params.codi : ""}
                  data={data}
                  imatge={imatge}
                  errorServei={errorServei}
                  mostrarMapaWeb={mostrarMapaWeb}
                  mostrarDataHora={mostrarDataHora}
                  loading={loading}
                  seccions={identificadorsSeccions}
                />
              ) : (
                ""
              )}

              {mostrarProcediment ? (
                <>
                  <Procediments
                    dataProcediment={dataProcediment}
                    dataNormativa={dataNormativa}
                    dataDocuments={dataDocuments}
                    dataTramit={dataTramits}
                    errorProcediment={errorProcediment}
                    loading={loading}
                  />
                </>
              ) : (
                ""
              )}

              {mostrarCerca ? (
                <ResultatsCercador
                  loading={false}
                  resultats={resultatsCerca}
                  cercador={query.get("query")}
                />
              ) : (
                ""
              )}

              {mostrarAnunci ? (
                <Fitxa
                  codi={props.codi !== undefined ? props.codi : ""}
                  data={data}
                  imatge={imatge}
                  errorServei={errorServei}
                  loading={loading}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
}

export default Index;
