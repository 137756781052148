import React from "react";
import "../css/Tramits.css";
import {Link} from "react-router-dom";

function CardTramit(props) {
  return (
    <div className="card bg-dark cardTramits">
      <img
        className="card-img imatgeTramits"
        alt="imatgeTramit"
        src={`data:;base64,` + props.img}
      />

      <div className="card-img-overlay">
        {/* <h5 className="card-title">{props.titol}</h5> */}
        <Link className="stretched-link" to={`/fitxa/${props.codi}`}></Link>
      </div>
    </div>
  );
}

export default CardTramit;
