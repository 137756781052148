import React from "react";
import "./css/Tramits.css";
import CardTramit from "./SubComponents/CardTramit";

function Tramits(props) {

  return (
    <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2">
      {props.data.map((dataCard, index) => (
        <CardTramit
          key={index}
          codi={dataCard.codigo}
          titol={dataCard.titol}
          img={dataCard.imatge}
        />
      ))}
    </div>
  );
}

export default Tramits;
