import React, { useEffect, useState } from "react";
import "../css/Fitxa.css";
import "./css/MapaWeb.css";
import Axios from "../../Axios";
import { Link } from "react-router-dom";
import Traduccio from "../Traduccio";
import SpinnerLoading from "../SubComponents/SpinnerLoading";
import { traduir } from "../../Constants";
import { ArrowLeft, Search } from "react-bootstrap-icons";
import Paginacio from "../SubComponents/Paginacio";
import { PlusCircleFill } from "react-bootstrap-icons";

function TaulerAnuncis(props) {
  const [dataTaulerAnuncis, setDataTaulerAnuncis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cercar, setCercar] = useState("");
  const [mostrarResultats, setMostrarResultats] = useState(false);
  const [resultats, setResultats] = useState([]);
  const [totalPagines, setTotalPagines] = useState();
  const [paginaActual, setPaginaActual] = useState(1);
  const [resultatsPerPagina] = useState(10);

  const carregarAnuncis = () => {
    setLoading(true);

    setPaginaActual(1);
    setCercar("");
    Axios.get("/anuncis", {
      params: {
        lang: localStorage.getItem("lang"),
        page: 1,
        pageSize: resultatsPerPagina,
      },
    })
      .then(function (response) {
        setDataTaulerAnuncis(response.data);
        setLoading(false);
        setMostrarResultats(false);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(true);
      });
  };

  useEffect(() => {
    carregarAnuncis();
  }, []);

  const recerca = (event) => {
    event.preventDefault();
    setLoading(true);

    Axios.post(
      `/cercarAnuncis?lang=${localStorage.getItem(
        "lang"
      )}&page=${1}&pageSize=${resultatsPerPagina}`,
      {
        paraulesClau: cercar,
      }
    )
      .then(function (response) {
        setResultats(response.data);
        setLoading(false);
        setMostrarResultats(true);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const getDia = (date) => {
    let dia = new Date(date);
    let lang = "es-ES";
    if (localStorage.getItem("lang") === "ca") {
      lang = "ca-CA";
    }
    return dia.toLocaleDateString(lang, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const paginacioNext = async (pag) => {
    setLoading(true);

    Axios.get(
      `/anuncis?lang=${localStorage.getItem("lang")}&page=${
        pag === "next" ? paginaActual + 1 : paginaActual - 1
      }&pageSize=${resultatsPerPagina}`,
      {}
    )
      .then(function (response) {
        setDataTaulerAnuncis(response.data);
        setLoading(false);
        setMostrarResultats(false);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });

    setPaginaActual(pag === "next" ? paginaActual + 1 : paginaActual - 1);
  };

  const paginacio = async (num_pag) => {
    setLoading(true);

    Axios.get(
      `/anuncis?lang=${localStorage.getItem(
        "lang"
      )}&page=${num_pag}&pageSize=${resultatsPerPagina}`,
      {}
    )
      .then(function (response) {
        setDataTaulerAnuncis(response.data);
        setLoading(false);
        setMostrarResultats(false);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });

    setPaginaActual(num_pag);

    setLoading(false);
  };

  const paginacioNextCerca = async (pag) => {
    setLoading(true);

    Axios.post(
      `/cercarAnuncis?lang=${localStorage.getItem("lang")}&page=${
        pag === "next" ? paginaActual + 1 : paginaActual - 1
      }&pageSize=${resultatsPerPagina}`,
      {
        paraulesClau: cercar,
      }
    )
      .then(function (response) {
        setResultats(response.data);
        setLoading(false);
        setMostrarResultats(true);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });

    setPaginaActual(pag === "next" ? paginaActual + 1 : paginaActual - 1);
  };

  const paginacioCerca = async (num_pag) => {
    setLoading(true);

    Axios.post(
      `/cercarAnuncis?lang=${localStorage.getItem(
        "lang"
      )}&page=${num_pag}&pageSize=${resultatsPerPagina}`,
      {
        paraulesClau: cercar,
      }
    )
      .then(function (response) {
        setResultats(response.data);
        setLoading(false);
        setMostrarResultats(true);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });

    setPaginaActual(num_pag);

    setLoading(false);
  };

  return (
    <div className="col-sm-8 col-md-8 col-lg-9 col-xl-10">
      {loading ? (
        <SpinnerLoading />
      ) : (
        <div className="container">
          <h1 className="titol text-center mb-3">{props.data.titol}</h1>

          {mostrarResultats && (
            <div
              className="d-flex flex-row-reverse ms-4"
              style={{ float: "right" }}
            >
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={(e) => {
                  setMostrarResultats(false);
                  carregarAnuncis();
                }}
              >
                <ArrowLeft className="me-2" fontSize={20} />
                <Traduccio string="cataleg.tornar" />
              </button>
            </div>
          )}
          <div className="row">
            <div className="col-lg-12">
              <form
                className="d-flex col"
                onSubmit={(e) => recerca(e)}
                style={{ float: "right" }}
              >
                <input
                  className="form-control cercarProcediment"
                  type="search"
                  placeholder={traduir("menu.cercadorAnuncis")}
                  aria-label="Search"
                  id="cercador"
                  defaultValue={""}
                  onChange={(e) =>
                    e.preventDefault() + setCercar(e.target.value)
                  }
                />
                <button
                  className="btn btn-outline-primary btnRecerca"
                  type="submit"
                  onClick={(e) => recerca(e)}
                >
                  <Search />
                </button>
              </form>
            </div>
          </div>
          {!mostrarResultats ? (
            <>
              {dataTaulerAnuncis?.anuncisFitxaList?.map((anunci, index) => {
                return (
                  <div className="card mb-1 p-2 cardResultat" key={index}>
                    <div className="row g-0">
                      <div className="col-md-10">
                        <div className="card-text procedimentLink">
                          <Link
                            to={`/anunci/${anunci.codigo}`}
                            className="procedimentLink"
                          >
                            <PlusCircleFill
                              style={{ fontSize: "19px" }}
                              className="me-2"
                            />
                            {anunci.titol}
                          </Link>
                        </div>
                        <div className="card-text procedimentFamilia">
                          {" "}
                          {getDia(anunci.dataPublicacio)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {dataTaulerAnuncis.numeroElementos > resultatsPerPagina && (
                <div className="row mt-5 m-0 justify-content-center">
                  <div className="col-auto">
                    <Paginacio
                      totalPagines={totalPagines}
                      paginaActual={paginaActual}
                      pagNext={paginacioNext}
                      paginacio={paginacio}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {resultats.numeroElementos === 0 ? (
                <div className="noResultatsCercaPrincipal mt-4">
                  <Traduccio string="cataleg.noCoincidenica" />
                </div>
              ) : (
                resultats?.anuncisFitxaList.map((result, index) => {
                  return (
                    <div className="card mb-1 p-2 cardResultat" key={index}>
                      <div className="row g-0">
                        <div className="col-md-10">
                          <div className="card-text procedimentLink">
                            <Link
                              to={`/anunci/${result.codigo}`}
                              className="procedimentLink"
                            >
                              <PlusCircleFill
                                style={{ fontSize: "19px" }}
                                className="me-2"
                              />
                              {result.titol}
                            </Link>
                          </div>
                          <div className="card-text procedimentFamilia">
                            {" "}
                            {getDia(result.dataPublicacio)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}

              {resultats.numeroElementos > resultatsPerPagina && (
                <div className="row mt-5 m-0 justify-content-center">
                  <div className="col-auto">
                    <Paginacio
                      totalPagines={totalPagines}
                      paginaActual={paginaActual}
                      pagNext={paginacioNextCerca}
                      paginacio={paginacioCerca}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default TaulerAnuncis;
