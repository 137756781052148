import React, { useEffect, useState } from "react";
import Axios from "../../Axios";
import SpinnerLoading from "../SubComponents/SpinnerLoading";


function Organigrama(props) {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

      useEffect(() => {
        setLoading(true);

        Axios.get("/organigramaDir3/"+process.env.REACT_APP_DIR3, {
          params: {
            lang: localStorage.getItem("lang"),
          },
        })
          .then(function (response) {
            setData(response.data.hijos);
            setLoading(false);
          })
          .catch(function (error) {});
      }, []);

    const childrenMap = (array) => {
        return (array.map((item, index) => {
            return (
                <ul key={item.codigo} style={{ listStyleType: 'none' }}>
                    <li className={`border p-1 my-3 rounded`}>
                        {item.codigo + ' - ' + item.denominacion}
                    </li>
                    {item?.hijos && childrenMap(item?.hijos)}
                </ul>
            )
        }))
    }
    return (
        <div className="col-sm-8 col-md-8 col-lg-9 col-xl-10">
            {loading ? (
                <SpinnerLoading />
            ) : (
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <h1 className="titol">
                            {props.data?.titol}
                        </h1>
                        <br />
                        {data?.map((item, index) => {
                            return (
                                <ul key={item.codigo} style={{ listStyleType: 'none' }}>
                                    <li className="border p-1 my-3 rounded">
                                        {item.codigo + ' - ' + item.denominacion}
                                    </li>
                                    {item?.hijos &&
                                        childrenMap(item?.hijos)
                                    }
                                </ul>
                            );
                        })}
                    </div>
                    <div className="col-1"></div>
                    {/* <div className="col-2">
                        <h1 className="titol text-center">
                            <Traduccio string="organigrama.llegenda" />
                        </h1>
                        <div className="border p-1">
                            <p className="text-center p-1 m-2 mx-4 text-white shadow-sm" style={{ border: `1px solid ${ENTITAT}`, background: ENTITAT }}>
                                <GlobeAmericas className="me-2" />
                                <Traduccio string="organigrama.entitat" />
                            </p>
                            <p className="text-center p-1 m-2 mx-4 text-white shadow-sm" style={{ border: '1px solid #4685e3', background: UNITAT }}>
                                <GlobeAmericas className="me-2" />
                                <Traduccio string="organigrama.unitatOrgànica" />
                            </p>
                            <p className="text-center p-1 m-2 mx-4 shadow-sm" style={{ border: '1px solid #76f3e7', background: DRET_PUBLIC }}>
                                <GlobeAmericas className="me-2" />
                                <Traduccio string="organigrama.entitatDretPublic" />
                            </p>
                            <p className="text-center p-1 m-2 mx-4 text-white shadow-sm" style={{ border: '1px solid #d55dd5', background: LLIBRE }}>
                                <Book className="me-2" />
                                <Traduccio string="organigrama.llibre" />
                            </p>
                            <p className="text-center p-1 m-2 mx-4 shadow-sm" style={{ border: '1px solid #ffa500b8', background: OFICINA }}>
                                <HouseDoor className="me-2" />
                                <Traduccio string="organigrama.oficinaPrincipal" />
                            </p>
                        </div>
                    </div> */}
                </div>

            )}
        </div>
    );
}

export default Organigrama;
