import React from "react";
import "../css/CardResultats.css";
import { Link } from "react-router-dom";
import Traduccio from "../Traduccio";
import { right } from "@popperjs/core";
import { PlusCircleFill } from "react-bootstrap-icons";

function CardResultats(props) {
  return (
    <div className="card p-2 mb-1 cardResultat">
      <div className="row g-0">
        <div className="col-md-10">
          <div className="card-text procedimentLink">
            <Link
              to={`/procediment/${props.data.codi}`}
              className="procedimentLink"
            >
              <PlusCircleFill style={{ fontSize: "19px" }} className="me-2" />
              {props.data.nom}
            </Link>
          </div>
          <div className="card-text procedimentFamilia">
            {" "}
            {props.data.tipusProcediment}
          </div>
        </div>
        {props.data.telematic && (
          <div className="col-md-2">
            <a
              href={`${props.data.identificadorsTramit[0].enllas}`}
              target="_blank"
              className="btn btn-primary col"
              style={{ float: right }}
              rel="noreferrer"
            >
              <Traduccio string="tramit.iniciar" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default CardResultats;
