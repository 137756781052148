import React from "react";
import "./css/Fitxa.css";
import DocumentCallOut from "./SubComponents/DocumentsCallOut";
import LinksCallOut from "./SubComponents/LinksCallOut";
import VideoYoutube from "./SubComponents/VideoYoutube";
import Traduccio from "./Traduccio";
import SpinnerLoading from "./SubComponents/SpinnerLoading";

function Fitxa(props) {
  const data = props.data;
  return (
    <>
      <div className="col-sm-8 col-md-8 col-lg-9 col-xl-10">
        {props.loading ? (
          <SpinnerLoading />
        ) 
        
        // : props.errorServei ? (
        //   <div className="container errorContainer text-center">
        //     {" "}
        //     <div className="mt-4">
        //       <Traduccio string="fitxa.errorServei" />
        //     </div>
        //   </div>
        // )
        
        : (
          <div className="container-fluid containerFitxa">
            <h1 className="titol"> {data.titol} </h1>
            {data.descripcioAbreujada && (
              <div className="mt-1 text justifyText">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.descripcioAbreujada,
                  }}
                ></div>
              </div>
            )}
            {data.imatge && (
              <img
                src={props.imatge}
                className="rounded mx-auto d-block img-fluid mt-3 mb-3"
                alt="Imatge"
                width="500px"
              />
            )}
            {data.descripcioExtensa && (
              <div className="mt-1 text mb-5 justifyText">
                {" "}
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: data.descripcioExtensa }}
                ></div>
              </div>
            )}
            {data.identificadorDocuments !== null && (
              <DocumentCallOut infoDocuments={data.identificadorDocuments} />
            )}
            {data.enllassos !== null && (
              <LinksCallOut enllassos={data.enllassos} />
            )}
            {data.videoYoutube !== "" && (
              <VideoYoutube enllasVideo={data.videoYoutube} />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Fitxa;
