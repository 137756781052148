import React, { useEffect, useState } from "react";
import logo from "../Img/MIDA1gl.jpg";
import {
  Calendar,
  ChevronDoubleRight,
  Clock,
  Person,
} from "react-bootstrap-icons";
import Traduccio from "./Traduccio";
import "./css/Header.css";
import { agafarIdioma, primary } from "../Constants";
import Axios from "../Axios";
import { Link } from "react-router-dom";

function Header(props) {
  const [data, setData] = useState();
  const [links, setLinks] = useState([]);
  const [hora, setHora] = useState([]);
  const [segons, setSegons] = useState(0);
  const [minuts, setMinuts] = useState(0);
  const [hores, setHores] = useState(0);

  let idioma = agafarIdioma();

  // function lauchTimer() {
  //   setInterval(function () {
  //     Axios.get("/hora", {
  //       params: {
  //         lang: idioma,
  //       },
  //     })
  //       .then(function (response) {
  //         setHora(response.data);
  //         setHores(response.data.hora);
  //         setMinuts(response.data.minuts);
  //         setSegons(response.data.segons);
  //       })
  //       .catch(function (error) {});
  //   }, 1000000);
  // }

  useEffect(() => {
    let intervalId = null;
    // setLoadingHora(true);
    Axios.get("/hora", {
      params: {
        lang: "ca",
      },
    })
      .then((response) => {
        setHora(response.data);
        let dia =  response.data.dia;
        let hores = response.data.hora;
        let minuts = response.data.minut;
        let segons = response.data.segon;

        intervalId = setInterval(function () {
          segons = segons + 1;

          if (segons === 60) {
            segons = 0;
            minuts = minuts + 1;
            if (minuts === 60) {
              minuts = 0;
              hores = hores + 1;
              if (hores === 24) {
                dia += 1;
                hores = 0;
              }
            }
          }
          setData(
            new Date(
              `${response.data.any}-${(response.data.mes)
                .toString()
                .padStart(2, 0)}-${dia.toString().padStart(2, 0)}T${hores
                .toString()
                .padStart(2, 0)}:${minuts.toString().padStart(2, 0)}:${segons
                .toString()
                .padStart(2, 0)}Z`
            )
          );
        }, 1000);
        // setLoadingHora(false);
      })
      .catch(function (error) {});
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const peticioCapsalera = () => {
    Axios.get("/capssalera", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        // setData(response.data);
        setLinks(response.data.fitxesCapssalera);
        // setHora(response.data.horaActual);

        // let segundos = response.data.horaActual.segon;
        // let hora = response.data.horaActual.hora;
        // let minutos = response.data.horaActual.minut;

        // setInterval(function () {
        //   segundos = segundos + 1;

        //   if (segundos === 60) {
        //     segundos = 0;
        //     minutos = minutos + 1;
        //     if (minutos === 60) {
        //       minutos = 0;
        //       hora = hora + 1;
        //       if (hora === 24) {
        //         hora = 0;
        //       }
        //     }
        //   }

        //   setSegons(segundos);
        //   setMinuts(minutos);
        //   setHores(hora);
        // }, 1000);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    peticioCapsalera();
  }, []);

  return (
    <nav className="navbar navbar-expand-lg header">
      <div className="container-fluid">
        <div className="justify-content-start">
          <ul className="nav">
            <li className="nav-item">
              <Link className="navbar-brand" to="/">
                {" "}
                <img
                  src={logo}
                  className="img-fluid"
                  alt="logoSCI"
                  width="280"
                />{" "}
              </Link>
            </li>
            <li className="nav-item navSeu">
              <Link className="seuElectronica" to="/">
                <Traduccio string="header.seuElectronica" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="justify-content-end">
          <ul className="nav justify-content-end navHeader">
            <li className="nav-item  mt-2 linkPortal">
              <a className="navPortals  " href={links[0]?.url}>
                <ChevronDoubleRight
                  color={primary}
                  className="iconoLink2 me-1"
                />
                {links[0] === undefined
                  ? "Portal transparencia"
                  : links[0].titol}
              </a>
            </li>
            <li className="nav-item linkPortal mt-2">
              <a className="navPortals " href={links[1]?.url}>
                <ChevronDoubleRight
                  color={primary}
                  className="iconoLink2 me-1"
                />
                {links[1] === undefined ? "Portal web" : links[1].titol}
              </a>
            </li>
            <li className="nav-item especialMarge">
              <button
                className={`nav-link links ${idioma === "ca" ? "active" : ""}`}
                onClick={() => props.canviarLlenguatge("ca")}
              >
                CA |
              </button>
            </li>

            <li className="nav-item">
              <button
                className={`nav-link links ms-1 ${
                  idioma === "es" ? "active" : ""
                }`}
                onClick={() => props.canviarLlenguatge("es")}
              >
                ES
              </button>
            </li>
            <li className="nav-item mt-1">
              <a className="nav-link" id="txt">
                {hora.length === 0 ||
                segons === undefined ||
                minuts === undefined ? (
                  <>
                    {" "}
                    <Calendar className="me-1 mb-1" />
                    00/00/00
                    <Clock className="ms-2 me-1 mb-1" /> 00:00:00
                  </>
                ) : (
                  <>
                    <Calendar className="me-1 mb-1" />
                    {hora.dia < 10 ? `0${hora.dia}` : hora.dia}/
                    {hora.mes < 10 ? `0${hora.mes}` : hora.mes}/{hora.any}
                    <Clock className="ms-2 me-1 mb-1" />
                    {/* {hores < 10 ? `0${hores}` : hores}:
                    {minuts < 10 ? `0${minuts}` : minuts}:
                    {segons < 10 ? `0${segons}` : segons} */}
                    {data?.toLocaleTimeString()}
                  </>
                )}
              </a>
            </li>
            <li className="nav-item mb-2 mt-1">
              <a
                className="btn btn-primary"
                href={links[2]?.url}
                target="_blank"
                rel="noreferrer"
              >
                <Person className="me-2 mb-1" />{" "}
                {links[2] === undefined ? "Zona Personal" : links[2].titol}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
