import React, { useEffect, useState } from "react";
import "../css/Fitxa.css";
import "./css/DataHora.css";
import Axios from "../../Axios";
import SpinnerLoading from "../SubComponents/SpinnerLoading";

function DataHora(props) {
  const [dataTemps, setDataTemps] = useState({});
  const [, setLoadingHora] = useState(false);
  const [segons, setSegons] = useState("0");
  const [minuts, setMinuts] = useState("0");
  const [hores, setHores] = useState("0");

  const [data, setData] = useState();

  useEffect(() => {
    let intervalId = null;
    setLoadingHora(true);
    Axios.get("/hora", {
      params: {
        lang: "ca",
      },
    })
      .then((response) => {
        setDataTemps(response.data);
        let dia = response.data.dia;
        let hores = response.data.hora;
        let minuts = response.data.minut;
        let segons = response.data.segon;

        intervalId = setInterval(function () {
          segons = segons + 1;

          if (segons === 60) {
            segons = 0;
            minuts = minuts + 1;
            if (minuts === 60) {
              minuts = 0;
              hores = hores + 1;
              if (hores === 24) {
                dia += 1;
                hores = 0;
              }
            }
          }

          setSegons(segons);
          setMinuts(minuts);
          setHores(hores);
          setData(
            new Date(
              `${response.data.any}-${response.data.mes
                .toString()
                .padStart(2, 0)}-${dia.toString().padStart(2, 0)}T${hores
                .toString()
                .padStart(2, 0)}:${minuts.toString().padStart(2, 0)}:${segons
                .toString()
                .padStart(2, 0)}Z`
            )
          );
        }, 1000);
        setLoadingHora(false);
      })
      .catch(function (error) {});
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // function refrescarHora() {
  //   Axios.get("/hora", {
  //     params: {
  //       lang: "ca",
  //     },
  //   })
  //     .then(function (response) {
  //       setDataTemps(response.data);
  //       let hores = response.data.hora;
  //       let minuts = response.data.minut;
  //       let segons = response.data.segon;

  //       setInterval(function () {
  //         segons = segons + 1;

  //         if (segons === 60) {
  //           segons = 0;
  //           minuts = minuts + 1;
  //           if (minuts === 60) {
  //             minuts = 0;
  //             hores = hores + 1;
  //             if (hores === 24) {
  //               hores = 0;
  //             }
  //           }
  //         }

  //         setSegons(segons);
  //         setMinuts(minuts);
  //         setHores(hores);
  //       }, 1000);

  //     })
  //     .catch(function (error) {});
  // }

  return (
    <div className="col-sm-8 col-md-8 col-lg-9 col-xl-10">
      {props.loading ? (
        <SpinnerLoading />
      ) : (
        <div className="container-fluid containerFitxa">
          <h1 className="titol"> {props.data.titol} </h1>
          {props.data.descripcioAbreujada && (
            <div className="mt-1 text">
              <div
                dangerouslySetInnerHTML={{
                  __html: props.data.descripcioAbreujada,
                }}
              ></div>
            </div>
          )}
          <div className="temps">
            <div className="dataHora col-auto text-center">
              {/* {loadingHora && (
                <>
                  <div className="dia">00/00/00</div>
                  <div className="hora">00:00:00</div>{" "}
                </>
              )} */}
              <div className="dia">
                {dataTemps.dia < 10 ? `0${dataTemps.dia}` : dataTemps.dia}/
                {dataTemps.mes < 10 ? `0${dataTemps.mes}` : dataTemps.mes}/
                {dataTemps.any}
              </div>
              <div className="hora">
                {/* {hores < 10 ? `0${hores}` : hores}:
                {minuts < 10 ? `0${minuts}` : minuts}:
                {segons < 10 ? `0${segons}` : segons} */}
                {data?.toLocaleTimeString()}
              </div>
            </div>
          </div>
          {/* <div className="temps mb-5 mt-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => refrescarHora()}
            >
              <Traduccio string="dataHora.refrescar" />
            </button>
          </div> */}

          {props.data.descripcioExtensa && (
            <div className="mt-1 text">
              {" "}
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: props.data.descripcioExtensa,
                }}
              ></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DataHora;
