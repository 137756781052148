import React from "react";
import "../css/Tramits.css";
import Axios from "../../Axios";
import logoPdf from "../../Img/icon_pdf.png";
import logoWord from "../../Img/icon.doc.png";
import logoExcel from "../../Img/icon_excel.png";
import Traduccio from "../Traduccio";

function TramitProcediment(props) {
  function documents(codi, nom) {
    Axios.get(`/arxiusStreaming`, {
      params: {
        id: codi,
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nom);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (error) {});
  }

  const tipusArxiu = (mime) => {
    if (mime?.includes("pdf")) {
      return logoPdf;
    } else if (mime?.includes("excel")) {
      return logoExcel;
    } else {
      return logoWord;
    }
  };

  return props.tramits?.map((tramit, index) => (
    <div className="card mt-4" key={index}>
      <div className="card-header titolTramit">{tramit.nom}</div>
      <div className="card-body">
        {tramit.descripcio && (
          <div className="mb-2">
            <div
              dangerouslySetInnerHTML={{
                __html: tramit.descripcio,
              }}
            ></div>
          </div>
          // <>
          //   <p className="card-text">{tramit.descripcio}</p>
          // </>
        )}
        {tramit.requisits && (
          <>
            <h6 className="card-title titolTramit">
              <Traduccio string="tramit.requisits" />
            </h6>
            <p className="card-text">{tramit.requisits ?? "---"}</p>
          </>
        )}

        {tramit.documentacio && (
          <>
            <h6 className="card-title titolTramit ">
              <Traduccio string="tramit.documentacio" />
            </h6>
            <p className="card-text">{tramit.documentacio}</p>
          </>
        )}
        <h6 className="card-title titolTramit">
          <Traduccio string="procediment.termini" />
        </h6>
        <p className="card-text">{tramit.terminiMaxim ?? "---"}</p>
        <h6 className="card-title titolTramit">
          <Traduccio string="tramit.organ" />
        </h6>
        <p className="card-text">{tramit.organCompetent ?? "---"}</p>

        {tramit.identificadorsModelsSolicitut && (
          <>
            <h6 className="card-title titolTramit">
              <Traduccio string="tramit.models" />
            </h6>

            {tramit.identificadorsModelsSolicitut.map((model, index) => {
              let logo = tipusArxiu(model.mime);
              return (
                <p className="card-text" key={index}>
                  <img
                    src={logo}
                    className="me-2 mb-2"
                    width="24px"
                    alt="tipusArxiu"
                  />
                  <button
                    className="linkDocument pt-2"
                    onClick={() => documents(model.codiArxiu, model.nomArxiu)}
                  >
                    {model.titol}
                  </button>
                </p>
              );
            })}
          </>
        )}
        {tramit.identificadorsDocumentacioInformativa && (
          <>
            <h6 className="card-title titolTramit">
              <Traduccio string="tramit.documentacioInformativa" />
            </h6>

            {tramit.identificadorsDocumentacioInformativa.map(
              (document, index) => {
                let logo = tipusArxiu(document.mime);
                return (
                  <p className="card-text" key={index}>
                    <img
                      src={logo}
                      className="me-2 mb-2"
                      width="24px"
                      alt="tipusArxiu"
                    />
                    <button
                      className="linkDocument pt-2"
                      onClick={() =>
                        documents(document.codiArxiu, document.nomArxiu)
                      }
                    >
                      {document.titol}
                    </button>
                  </p>
                );
              }
            )}
          </>
        )}
        {tramit.telematic && (
          <a href={tramit.enllas} className="btn btn-primary" target="blank">
            <Traduccio string="tramit.iniciar" />
          </a>
        )}
      </div>
    </div>
  ));
}

export default TramitProcediment;
